import React from "react";
import { Col, Row, Button } from "react-bootstrap"
import { GatsbyImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components"

// styles
const Projecttitle = styled.h2`
  color: ${({ theme }) => theme.colours.common.white};
  font-weight: 400;
  font-family: ${({ theme }) => theme.font.headings};
  text-transform: uppercase;
  margin: -25px -25px 10px -25px;
  font-size: 1.3rem;

  
  background: #251e21;
  padding: 10px 20px;

  @media (min-width: 768px) { 
    font-size: 1.5rem;
  }

`


const Projectdescription = styled.div`
  color: ${({ theme }) => theme.colours.common.black};
  margin-bottom: 15px;
  font-size: 1.1rem;
  height: 160px;
  p {
    line-height: 1.2;
  }

  & a {
    color: ${({ theme }) => theme.colours.primary.main};
    text-decoration: none;
    transition: ${({ theme }) => theme.animations.link};
  }
  
  & a:hover {
    color: ${({ theme }) => theme.colours.primary.main};
    font-weight: bold;
  }

  @media (min-width: 576px) { 

    height: 260px;
  }
  

  @media (min-width: 768px) { 

    height: auto;
  }
  
`

const Projectimg = styled(GatsbyImage)`
  margin: 0 auto;
  z-index: -1;
`

const Projectrow = styled(Row)`
  margin: 20px 0 50px 0;
  justify-content: center;
  align-items: flex-start;

  @media (min-width: 768px) { 

  & .col-md-6 {
    // padding-right: 0!important;
    // padding-left: 0!important;
  }
  }
`

const Learnlink = styled(Link)`
text-decoration: none !important;

& h2:hover {
  color: ${({ theme }) => theme.colours.primary.main};
}
`
const Itemcontainer = styled.div`
  background-color: ${({ theme }) => theme.colours.common.white};
  box-shadow: ${({ theme }) => theme.shadows.shadow1};
  padding: 25px;
  @media (min-width: 768px) { 
    margin-top: -25px;
  }
`

function Homeserviceslist({ children }) {
 
  const data = useStaticQuery(graphql`
  query {
    directus {
      concut_services(filter: {featured: {_eq: true}, status: {_eq: "Published"}}) {
        title
        slug
        seo_description
        service_image {
          id
          title
          imageFile {
            childImageSharp {
              gatsbyImageData(height: 150, width: 400, layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
        }
      }
    }
  }
  
   
  `)

  const serviceNode = data.directus.concut_services;
 
  return (
    
    <>
      <Projectrow>
        {serviceNode.map((serviceItem, i) => 
        (
          <Col xs={12} sm={6} md={6} lg={4} className="my-3" key={i}>
{(() => {
        
          return (
          <>
            <Learnlink to={`/services/${serviceItem.slug}`}>
              <Projectimg image={serviceItem.service_image.imageFile.childImageSharp.gatsbyImageData} alt={serviceItem.title} objectPosition="center center" />
            </Learnlink>
            <Itemcontainer>
                <Learnlink to={`/services/${serviceItem.slug}`}><Projecttitle>{serviceItem.title}</Projecttitle></Learnlink>
                  <Projectdescription dangerouslySetInnerHTML={{
                        __html: serviceItem.seo_description,
                    }} />
                    {/* <Learnlink to={`/services/${serviceItem.slug}`}>
                      <StyledButton>Learn More</StyledButton>
                    </Learnlink> */}
              </Itemcontainer>
          </>
          )
        
      })()}
            </Col>
             ))}    

             </Projectrow>
    
  </>
  );
}

export default Homeserviceslist;